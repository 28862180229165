import React from 'react';
import Layout from './src/components/Layout';
import SpecialLayout from './src/components/SpecialLayout';
import CookieBanner from './src/components/CookieBanner';

export const wrapRootElement = ({ element }) => (
  <>
    <CookieBanner />
    {element}
  </>
);

export function wrapPageElement({ element, props }) {
  const path = props.location.pathname;

//  if (path.includes('snap-ai-x')) {
  //  return <SpecialLayout {...props}>{element}</SpecialLayout>;
 // }

  return <Layout {...props}>{element}</Layout>;
}

import React, { useState, useEffect, Fragment, useRef } from 'react';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import MegaMenu from '../../data/MegaMenu';
import Button from '../Button';
import Shape from '../../assets/shapes/squared-corners-fluid.inline.svg';
import BackButton from './BackButton';
import BackButtonL3 from './BackButtonL3';
//

const Menu = ({
  menuOpen,
  menuL1,
  setMenuL1,
  menuL2,
  setMenuL2,
  menuL3,
  setMenuL3,
  closeMenu
}) => {
  // *
  // ** Handle Level ONE Menu

  const [isSafari, setIsSafari] = useState(false);
  const [divMenu2, setDivMenu2] = useState({}); // Set initial width
  const [divMenu3, setDivMenu3] = useState({}); // Set initial width
  const [divMenu4, setDivMenu4] = useState({}); // Set initial width
    

  const MenuOpener = ({ target }) => {
    const ua = window.navigator.userAgent.toLowerCase();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const minDesktopWidth = 1024; // Typical minimum desktop width
    const minDesktopHeight = 100; // Typical minimum desktop height
    //const windowWidth = document.documentElement.clientWidth;
    //const windowHeight = document.documentElement.clientHeight;

    const level = parseInt(target.dataset.level);
    const link = target.dataset.panelLink;
    const panel = document.querySelector(`[data-panel-name="${link}"]`);

    target.classList.toggle('active');

    if (level === 1) {
      if (!menuL1) {
        // ** There's no menu open at all
        // ** There's no menu open at all
        // ** only menu2 open
        if (typeof window !== 'undefined') {
            if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
              setIsSafari(true);
            }
        }
        setMenuL1(panel);
      } else if (menuL1) {
        // ** There's a menu so close this menu and clear any open sub menus
        setMenuL1(null);
        setMenuL2(null);
        const allPanels = document.querySelectorAll('[data-panel-name]');
        allPanels.forEach((element) => {
          element.classList.add('hidden');
        });

        const activeNavItems = document.querySelectorAll(
          '[data-level] .active'
        );
        activeNavItems.forEach((element) => {
          if (element.innerHTML !== target.innerHTML) {
            element.classList.remove('active');
          }
        });

        if (menuL1.innerHTML === panel.innerHTML) {
          // ** There's a menu open and they're the same, so hide it
          menuL1.classList.add('hidden');
          setMenuL1(null);
        } else {
          // ** There's a menu open and it's not the same as the one the user
          // ** is trying to open, so hide the old and show the new
          menuL1.classList.add('hidden');
          setMenuL1(panel);
        }
      }
    }

    //menu 2-3 open
    if (level === 2) {
      const activeNavItems = document.querySelectorAll(
        '[data-level="2"] .active'
      );
      activeNavItems.forEach((element) => {
        if (element.innerHTML !== target.innerHTML) {
          element.classList.remove('active');
        }
      });

      if (!menuL2) {
        // ** There's no menu open at all
        // ** Only menu2 and menu3 open
        if (typeof window !== 'undefined') {
        if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
          setIsSafari(true);
          //first time open menu3
          document.querySelector('[data-level="2"]').style.setProperty('width', '60vw', 'important');
        }else if (ua.indexOf('firefox') !== -1 && ua.indexOf('chrome') === -1) {
                if (windowWidth > 1366) {
                  setDivMenu3({padding: '3%', width:'22%'});
                }
                if (windowWidth <= 1366) {
                  setDivMenu3({padding: '3%', width:'22%'});
                }
                if (windowWidth <= 1024) {
                  setDivMenu3({width: '25%', padding: '3%', transform:'translateX(36vw)'});
                }
            }
        }
        setMenuL2(panel);
      } else if (menuL2) {
        // ** There's a menu open
        setMenuL3(null);

        const allChildPanels = panel.querySelectorAll('[data-panel-name]');
        allChildPanels.forEach((element) => {
          element.classList.add('hidden');
        });

        if (menuL2.innerHTML === panel.innerHTML) {
          // ** There's a menu open and they're the same, so hide it
          if (typeof window !== 'undefined') {
            if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
              setIsSafari(true);
              //close menu3
              setDivMenu2({width:'30vw'});
            }
          }
          menuL2.classList.add('hidden');
          setMenuL2(null);
        } else {
          // ** There's a menu open and it's not the same as the one the user
          // ** is trying to open, so hide the old and show the new
          if (typeof window !== 'undefined') {
            if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
              setIsSafari(true);
              //hide the menu3 then show again
              //setDivMenu2({width:'60vw !important'});
              document.querySelector('[data-level="2"]').style.setProperty('width', '60vw', 'important');
            }
            else if (ua.indexOf('firefox') !== -1 && ua.indexOf('chrome') === -1) {
                if (windowWidth >= 1366) {
                  setDivMenu3({padding: '3%'});
                }
                if (windowWidth > 1024 && windowWidth < 1366) {
                  setDivMenu3({width:'36%', padding: '3%'});
                }
                if (windowWidth <= 1024) {
                  setDivMenu3({width:'36%', padding: '3%'});
                }
            }
          }
          menuL2.classList.add('hidden');
          setMenuL2(panel);
        }
      }
    }

    if (level === 3) {
      const activeNavItems = document.querySelectorAll(
        '[data-level="3"] .active'
      );
      activeNavItems.forEach((element) => {
        if (element.innerHTML !== target.innerHTML) {
          element.classList.remove('active');
        }
      });

      if (!menuL3) {

        // ** There's no menu open at all
        if (typeof window !== 'undefined') {
          if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
            setIsSafari(true);
            //setDivMenu2({width:'99vw'});
            document.querySelector('[data-level="2"]').style.setProperty('width', '100vw', 'important');
          }
        }

        setMenuL3(panel);
      } else if (menuL3) {
        // ** There's a menu open

        if (menuL3.innerHTML === panel.innerHTML) {
          // ** There's a menu open and they're the same, so hide it

          if (typeof window !== 'undefined') {
              if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
                setIsSafari(true);
                //hide menu4
                //setDivMenu2({width:'60vw !important'});
                document.querySelector('[data-level="2"]').style.setProperty('width', '60vw', 'important');
              }
          }
          menuL3.classList.add('hidden');
          setMenuL3(null);
        } else {
          // ** There's a menu open and it's not the same as the one the user
          // ** is trying to open, so hide the old and show the new
          if (typeof window !== 'undefined') {
            if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
              setIsSafari(true);
              //setDivMenu2({width:'100vw'});
              document.querySelector('[data-level="2"]').style.setProperty('width', '100vw', 'important');
            }
          }
          menuL3.classList.add('hidden');
          setMenuL3(panel);
        }
      }
    }
  };

  useEffect(() => {
    if (menuL1) {
      menuL1.classList.remove('hidden');
    }
  }, [menuL1]);

  useEffect(() => {
    if (menuL2) {
      menuL2.classList.remove('hidden');
    }
  }, [menuL2]);

  useEffect(() => {
    if (menuL3) {
      menuL3.classList.remove('hidden');
    }
  }, [menuL3]);

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      return;
    }

    if (!menuOpen) {
      const allPanels = document.querySelectorAll('[data-panel-name]');
      allPanels.forEach((element) => {
        element.classList.add('hidden');
      });

      const activeNavItems = document.querySelectorAll('[data-level] .active');
      activeNavItems.forEach((element) => {
        element.classList.remove('active');
      });
    }

    const depth = document.querySelector('[data-menu-depth]');
    let level = null;

    if (menuL1) {
      level = menuL1.dataset.level;
    }

    if (menuL2) {
      level = menuL2.dataset.level;
    }

    if (menuL3) {
      level = menuL3.dataset.level;
    }

    if (menuOpen && level) {
      depth.dataset.menuDepth = level;
    } else {
      depth.dataset.menuDepth = '0';
      setMenuL1(null);
      setMenuL2(null);
      setMenuL3(null);
    }
  }, [menuOpen, menuL1, setMenuL1, menuL2, setMenuL2, menuL3, setMenuL3]);

  const closeOneMenu = (menu, panelLink) => {
    switch (menu) {
      case 1:
        setMenuL1(null);
        break;
      case 2:
        setMenuL2(null);
        break;
      case 3:
        setMenuL3(null);
        break;
      default:
        break;
    }

    const depth = document.querySelector('[data-menu-depth]');
    depth.dataset.menuDepth = menu;

    const panel = document.querySelector(`[data-panel-name="${panelLink}"]`);
    panel.classList.add('hidden');

    const active = document.querySelector(`[data-level="${menu}"] .active`);
    active.classList.remove('active');
  };

  
  // ** Build the menu
  return (
    <GlobalNav
      aria-label="Main Menu"
      data-menu-open={menuOpen ? 'true' : 'false'}
      data-menu-depth="0"
    >
      <GlobalNavMenu data-level="1">
        {MegaMenu.map((l1) => {
          const panelLink = nanoid();

          return (
            <Fragment key={l1.id}>
              <L1Link
                as={l1.link ? 'a' : 'button'}
                href={l1.link ? l1.link : ''}
                type={l1.link ? '' : 'button'}
                data-level="1"
                data-panel-link={!l1.link && panelLink}
                data-behaviour={`globalNav:${
                  l1.link ? 'plainLink' : 'panelLink'
                }`}
                onClick={(e) => !l1.link && MenuOpener(e)}
              >
                {l1.title}
              </L1Link>
              {l1.children && (
                <Panel
                  key={l1.id}
                  data-level="2"
                  data-panel-name={panelLink}
                  className="hidden"
                  style={divMenu2}
                >
                                  
                  <BackButton onClick={() => closeOneMenu(1, panelLink)} />
                  
                  <ul className="globalNav__panel__content globalNav__panel__l2">
                    {l1.children.map((l2) => (
                      <li className="globalNav__section" key={l2.id}>
                        {l2.eyebrow && <Eyebrow>{l2.eyebrow}</Eyebrow>}
                        <ul>
                          {l2.links.map((l3) => {
                            const panelLinkL3 = nanoid();

                            return (
                              <L2Link key={l3.id}>
                                {l3.links && typeof l3.links === 'string' ? (
                                  <a
                                    href={`/${l3.links}`}
                                    className={"gn__linkHeading "+`${l3.customClass}`}
                                  >
                                    {l3.title}
                                  </a>
                                ) : (
                                  <button
                                    type="button"
                                    aria-expanded="false"
                                    className="gn__linkHeading"
                                    data-level="2"
                                    data-panel-link={panelLinkL3}
                                    onClick={(e) => MenuOpener(e)}
                                  >
                                    {l3.title}
                                  </button>
                                )}

                                <Panel
                                  data-level="3"
                                  data-panel-name={panelLinkL3}
                                  className="hidden"
                                  as="ul"
                                  style={divMenu3}
                                >
                                  <div>
                                    <BackButtonL3
                                      onClick={() =>
                                        closeOneMenu(2, panelLinkL3)
                                      }
                                    />
                                  </div>

                                  <p>{l3.description}</p>

                                  {l3.links && typeof l3.links === 'string' ? (
                                    <a href={l3.links}>{l3.title}</a>
                                  ) : (
                                    l3.links.map((l4, index) => {
                                      const panelLinkL4 = nanoid();

                                      return (
                                        <L2Link key={index}>
                                          <button
                                            type="button"
                                            aria-expanded="false"
                                            className="gn__linkHeading"
                                            data-level="3"
                                            data-panel-link={panelLinkL4}
                                            onClick={(e) => MenuOpener(e)}
                                          >
                                            {l4.title}
                                          </button>

                                          <Panel
                                            data-level="4"
                                            data-panel-name={panelLinkL4}
                                            className="hidden"
                                          >
                                            <div className="contentContainer">
                                              <h3>{l4.title}</h3>
                                              <p className="intro">
                                                {l4.content.intro}
                                              </p>
                                              {l4.content.tags && (
                                                <ul>
                                                  {l4.content.tags.map(
                                                    (tag, tagIndex) => (
                                                      <li key={tagIndex}>
                                                        {tag}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              )}
                                              <div className="image">
                                                {l4.content.image && (
                                                  <img
                                                    src={l4.content.image}
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                              {l4.content.outro && (
                                                <p className="outro">
                                                  {l4.content.outro}
                                                </p>
                                              )}
                                              <LearnMore>
                                                <Button
                                                  content={{
                                                    label: 'Learn more',
                                                    // to: `${l4.link}`,
                                                    arrow: 90,
                                                  }}
                                                  callback={() => {
                                                    closeMenu();
                                                    navigate(`/${l4.link}`);
                                                  }}
                                                  outline
                                                />
                                                <br />
                                                <Button
                                                  content={{
                                                    label: 'Go back',
                                                    arrow: -90,
                                                  }}
                                                  callback={() => {
                                                    closeOneMenu(
                                                      3,
                                                      panelLinkL4
                                                    );
                                                  }}
                                                  outline
                                                />
                                              </LearnMore>
                                            </div>

                                            <div className="shapeContainer">
                                              <Shape />
                                            </div>
                                          </Panel>
                                        </L2Link>
                                      );
                                    })
                                  )}
                                </Panel>
                              </L2Link>
                            );
                          })}
                        </ul>
                      </li>
                    ))}
                  </ul>
                  
                </Panel>
              )}
            </Fragment>
          );
        })}

         <L1Link
           as="a"
           href="https://info.fulcrumgt.com/en-us/request-a-demo-with-a-product-expert"
           target="_blank"
           data-level="1"
           data-behaviour="plainLink"
           className="book"
         >
           Book a demo
        </L1Link>

        <L1Link
          as="a"
          href="https://info.fulcrumgt.com/en-us/request-a-demo-with-a-product-expert"
          data-level="1"
          data-behaviour="plainLink"
          className="mobile-only"
        >
          Book a demo
        </L1Link>

        <L1Link
          as="a"
          href="https://snappps.fulcrumgt.com/"
          data-level="1"
          data-behaviour="plainLink"
          className="mobile-only"
        >
          Login
        </L1Link>

        <L1Link
          as="a"
          href="/contact"
          data-level="1"
          data-behaviour="plainLink"
          className="mobile-only"
        >
          Contact
        </L1Link>
      </GlobalNavMenu>
    </GlobalNav>
  );
};

export default Menu;

const GlobalNav = styled.nav`
  --menuWidthL1: 100vw;
  --menuWidthL2: 100vw;
  --menuWidthL3: 100vw;

  visibility: hidden;
  height: 0;
  transition: var(--nav-transition);
  opacity: 0;
  background-color: var(--blue--3);
  pointer-events: none;

  .book {
    display: flex;
    place-content: center;
    -webkit-box-pack: center;
    min-width: 9.6rem;
    padding: 1rem 1.2rem;
    transition: all 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    border-width: 1px;
    border-style: solid;
    font-size: 1.2rem;
    line-height: 1.8rem;
    cursor: pointer;
    background-color: var(--blue--2);
    color: white;
    float: right;
    position: relative;
  }

  .book a:hover{
    color: black;
    background-color: transparent; 
  }

  @media (min-width: 1024px) {
    --menuWidthL1: 40vw;
    --menuWidthL2: 25vw;
    --menuWidthL3: 35vw;

    visibility: visible;
    height: auto;
    opacity: 1;
    pointer-events: unset;

    .mobile-only {
      display: none;
    }
  }

  /* css mobile display */
  @media (max-width: 1023px) {
    .book {
      display: none;
    }
    &[data-menu-open='true'] {
      visibility: visible;
      height: 100%;
      opacity: 1;
      pointer-events: unset;

      &[data-menu-depth='0'] {
        div[data-level='2'] {
          transition: none;
        }
      }

      &[data-menu-depth='2'] {
        div[data-level='1'] {
          transform: translateX(-100vw);
          transition: none;
        }
        div[data-level='2'] {
          height: 85vh;
          overflow: auto;
          transform: translateX(100vw);
          transition: none;
          width:85%;
        }
      }

      &[data-menu-depth='3'] {
        div[data-level='1'],
        div[data-level='2'] {
          transform: translateX(-100vw);
        }
        ul[data-level='3'] {
          transform: translateX(200vw);
          /*width:85%;*/
          padding-left: 5%;
        }
      }

      &[data-menu-depth='4'] {
        div[data-level='1'],
        div[data-level='2'],
        div[data-level='3'] {
          transform: translateX(-100vw);
        }
        ul[data-level='4'] {
          transform: translateX(300vw);
          overflow: auto;
        }
      }
    }
  }

`;

const GlobalNavMenu = styled.div`
  /* .globalNav__menu */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(var(--menuWidthL1) - (var(--menuGutter) * ));
  height: calc(100vh - var(--menuHeight));
  padding: var(--menuGutter) calc(var(--menuGutter) * 1.5);

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 48rem; /* adjust max width for menu items */
  }

  @media (min-width: 1024px) {
    height: var(--menuHeight);
  }
`;

const L1Link = styled.button`
  display: flex;
  position: relative;
  z-index: 350;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-bottom: var(--menuGutter);
  padding: 0;
  border: 0;
  background: transparent;
  color: var(--blue--1);
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.2rem;
  text-align: left;
  cursor: pointer;

  @media (min-width: 640px) {
    font-size: 3.2rem;
    line-height: 4rem;
  }

  @media (min-width: 1024px) {
    margin-bottom: 0;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  &.active {
    color: var(--blue--2);
    text-decoration: underline;
  }

  &:hover {
    color: var(--blue--2);
    text-decoration: underline;
    text-decoration-thickness: 0.1rem;
  }

  &.book:hover{
      color: black;
      text-decoration: underline;
      text-decoration-thickness: 0.1rem;
      background-color: transparent;
  }
`;

const L2Link = styled.li`
  margin-bottom: calc(var(--menuGutter) / 4);

  &:last-of-type {
    margin-bottom: 0;
  }

  .gn__linkHeading {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    color: var(--blue--1);
    font-family: var(--font-serif);
    font-size: 2.2rem;
    font-weight: 300;
    line-height: 3rem;
    text-align: left;
    cursor: pointer;

    @media (min-width: 1280px) {
      font-size: 3rem;
      line-height: 3.4rem;
    }

    &.active {
      color: var(--blue--2);
      text-decoration: underline;
      text-decoration-thickness: 0.2rem;
    }

    &:hover {
      color: var(--blue--2);
      text-decoration: underline;
      text-decoration-thickness: 0.2rem;
    }
  }
`;

const Panel = styled.div`
  /* .globalNav__panel */
  position: fixed;
  z-index: 300;
  @media (min-width: 1024px) {
     z-index: 500;
  }
  
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: calc(var(--menuWidthL1) - (var(--menuGutter) * 3));
  padding: var(--menuGutter) calc(var(--menuGutter) * 1.5);
  transition: var(--nav-transition);
  opacity: 1;
  background-color: var(--blue--3);

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .globalNav__panel__l2 {
    @media (min-width: 1024px) {
      padding-top: calc(var(--menuHeight) * 2 + (var(--menuGutter) * 3));
    }

    .globalNav__section {
      margin-bottom: var(--menuGutter);
      padding-bottom: var(--menuGutter);
      border-bottom: 1px solid var(--white);

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border: 0;
      }
    }
  }

  &[data-level='2'] {
    transition:none;

    @media (min-width: 1024px) {
      overflow:auto;
      width: calc(var(--menuWidthL1) - (var(--menuGutter) * 3));
    }

    @media (min-width: 1366px) {
      overflow:auto;
      width: calc(var(--menuWidthL1) - (var(--menuGutter) * 3.8)) !important;
    }

  }

  &[data-level='3'] {
    display: flex;
    z-index: 250;
    flex-direction: column;
    justify-content: flex-start;
    width: -webkit-calc(var(--menuWidthL2) - (var(--menuGutter) * 3));
    transform: translateX(var(--menuWidthL1));    
    padding: var(--menuGutter) -webkit-calc(var(--menuGutter) * 1.5);
    
    @media (min-width: 1024px) {
      width: -webkit-calc(var(--menuWidthL1) - (var(--menuGutter)*7));
      transform: translateX(39.5vw);
      justify-content: center;
    }

    @media (min-width: 1366px) {
      width: -webkit-calc(var(--menuWidthL1) - (var(--menuGutter)*7));
      transform: translateX(37.5vw);
      justify-content: center;
    }

    background-color: var(--white);
      /*transform: translateX(0);*/
      
    & > p {
      margin-bottom: 1.6rem;
      padding-bottom: 1.6rem;
      border-bottom: 1px solid var(--blue--3);
      color: var(--blue--1);
      font-size: 1.4rem;
      line-height: 2rem;

      @media (min-width: 1280px) {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }

    & > li {
      margin-bottom: 1.6rem;
      padding-bottom: 1.6rem;
      border-bottom: 1px solid var(--blue--3);

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }

      &:after {
        box-sizing: initial !important;
      }
    }
  }

  &[data-level='4'] {
    z-index: 200;
    /*width: var(--menuWidthL3);
      transform: translateX(var(--menuWidthL2));
    */
    width:33vw !important;
    transform: translateX(28vw);
    padding: 0;
    background-color: var(--blue--0);
    color: var(--white);
    text-align: center;

    .contentContainer {
      display: flex;
      flex-direction: column;
      /*width: calc(var(--menuWidthL3) - (var(--menuGutter) * 3));
        height: calc(100% - (var(--menuGutter) * 6) - var(--menuHeight));
      */
      width: 80%;
      height: 90%;
      padding: var(--menuGutter) calc(var(--menuGutter) * 1.5);
      overflow-x: auto;
      overflow-y: visible;

      & > * {
        margin: auto;
      }

      & > h3 {
        margin-bottom: var(--menuGutter);
        font-family: var(--font-serif);
        font-size: 2.2rem;
        line-height: 3rem;

        @media (min-width: 768px) {
          margin-bottom: var(--menuGutter);
        }

        @media (min-width: 1280px) {
          font-size: 3rem;
          line-height: 3.4rem;
        }
      }

      & > .intro {
        max-width: 48rem;
        margin-bottom: var(--menuGutter);
        color: var(--blue--2);
        font-family: var(--font-serif);
        font-size: 1.8rem;
        line-height: 2.4rem;

        @media (min-width: 768px) {
          margin-bottom: var(--menuGutter);
        }
      }

      & > ul {
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: 1.2rem;
        margin-bottom: var(--menuGutter);

        @media (min-width: 768px) {
          margin-bottom: var(--menuGutter);
        }

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.6rem;
          border-radius: 0.4rem;
          background-color: var(--blue--2);
          font-size: 1.2rem;
          line-height: 1.8rem;
        }
      }

      & > .image {
        display: none;
        width: calc(100% - (var(--menuGutter) * 3));
        max-height: 38rem;
        margin-bottom: var(--menuGutter);
        transform: translateX(calc(var(--menuGutter) * 1.5));

        @media (min-width: 1024px) {
          transform: translateX(calc(var(--menuGutter) * 0));
        }

        @media (min-width: 768px) {
          margin-bottom: var(--menuGutter);
        }

        @media (min-width: 768px) {
          display: unset;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: right;
        }
      }

      & > .outro {
        max-width: 48rem;
        margin-bottom: var(--menuGutter);
        font-size: 1.4rem;
        line-height: 2rem;

        @media (min-width: 768px) {
          margin-bottom: var(--menuGutter);
        }

        @media (min-width: 1280px) {
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
      }
    }
    
    @media (min-width: 1024px) {
      width: calc(var(--menuWidthL3) - (var(--menuGutter) * ));
    }

    @media (min-width: 1366px) {
      width: calc(var(--menuWidthL3) - (var(--menuGutter) * ));
    }

    & .shapeContainer {
      display: flex;
      position: fixed;
      z-index: -10;
      top: 0;
      align-items: center;
      justify-content: center;
      height: 100%;
      transform: translateX(12%);

      svg {
        width: 90vw;
        height: 100vw;
        transform: scale(2) translateX(45vw);
        fill: var(--blue--1);

        @media (min-width: 640px) {
          transform: unset;
        }
      }
    }
  }
`;

const Eyebrow = styled.h2`
  margin-bottom: 1.6rem;
  color: var(--blue--2);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
`;

const LearnMore = styled.div`
  @media (min-width: 1024px) {
    > *:not(:first-child) {
      display: none;
    }
  }
`;

Menu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  menuL1: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]).isRequired,
  setMenuL1: PropTypes.func.isRequired,
  menuL2: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]).isRequired,
  setMenuL2: PropTypes.func.isRequired,
  menuL3: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]).isRequired,
  setMenuL3: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isSafari: PropTypes.bool.isRequired, 
};
